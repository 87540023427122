.distance-error-block {
  width: 100%;
  margin-top: 20px;
  position: relative;
}
.distance-info {
  position: absolute;
  top: 10px;
  right: 27px;
  font-weight: 500;
}
.distance-info span {
  font-size: 25px;
  color: var(--main-color);
  font-weight: 600;
}
.distance-error-image {
  width: 100%;
}
.distance-error-message {
  color: var(--black);
  text-align: center;
}
.distance-error-message.main-message {
  font-size: 28px;
  font-weight: 600;
}

.distance-error-message.second-message {
  width: 80%;
  margin: 0 auto;
  margin-top: 14px;
  font-size: 18px;
  font-weight: 500;
}
.distance-error-message.third-message {
  width: 80%;
  margin: 0 auto;
  margin-top: 14px;
  font-size: 20px;
  font-weight: 500;
  font-size: 15px;
}

.astronaut-block {
  width: 70%;
  margin: 0 auto;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.astronaut {
  position: relative;
  z-index: 2;
  animation: astronaut-levitation-animation 2s ease-in-out infinite;
}
.astronaut-shadow {
  width: 120px;
  height: 10px;
  margin-top: -30px;
  opacity: 0.5;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  z-index: 1;
  box-shadow: 0 0 7px 0 rgb(0 0 0 / 20%);
  animation: astronaut-shadow-scale 2s ease-in-out infinite;
}

/* ANIMATION */
@keyframes astronaut-levitation-animation {
  50% {
    transform: translateY(-15px);
  }
}

@keyframes astronaut-shadow-scale {
  50% {
    transform: scale(0.9);
  }
}