.language-select {
  position: absolute;
  right: 15px;
  padding: 10px 15px;
  border-radius: 20px;
  border: none;
  outline: none;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: white;
  background-repeat: no-repeat;
  background-position: 95% center;
  transition: all 0.3s;
  /* color: #509aff; */
  color: #323232;
}
