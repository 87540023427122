.notification-container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.notification-block {
  height: 180px;
  width: 180px;
  padding: 0 25px;
  position: fixed;
  top: 35%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--white);
  box-shadow: 0 0 8px -3px var(--black);
  border-radius: 25px;
  transition: all 0.4s ease-in-out 0s;
  animation: show-notification-block 0.4s;
}

/* .notification-block.confirm {
  width: 200px;
  height: 200px;
} */

@keyframes show-notification-block {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.notification-icon {
  margin-bottom: 15px;
}

.notification-message {
  text-align: center;
}

.notifications-confirm-block {
  width: 100%;
  margin-top: 14px;
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
}

.confirm-button {
  background: none;
  border: none;
  font-size: 19px;
  font-weight: 500;
  /* color: #509aff; */
  cursor: pointer;
}

.confirm-button.no {
  /* color: #ff2d55; */
}