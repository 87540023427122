.header-container {
  width: 100vw;
  height: 28vh;
  color: var(--black);
  padding: 15px;
  background-repeat: no-repeat;
  background-blend-mode: multiply;
  background-color: var(--white);
  background-position: center;
  background-size: cover;
  margin-bottom: -30px;
  box-shadow: 0 0 15px 0 rgb(0 0 0 / 15%);
  background-image: url("https://avatars.mds.yandex.net/get-altay/2767250/2a00000174115881cc5f66df31ce3d8849a5/XXL");
  background-blend-mode: multiply;
  background-color: rgba(0, 0, 0, 0.4);
  background-size: cover;
  background-position: center;
}

.header-container_info {
  margin: 10px 15px;
  display: flex;
  align-items: center;
}

.container-info_name {
  margin-left: 8px;
  margin-right: 16px;
  font-size: 1.5rem;
  color: whitesmoke;
}

.container-info_name:empty {
  margin-left: 0;
  margin-right: 0;
}

.avatar {
  width: 54px;
  height: 54px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 100%;
}

.instagram-stories-avatar {
  border-radius: 50%;
  /* border: 2px dashed #ffffff; */
  /* animation: border-roll 4s ease-in-out infinite alternate-reverse; */
}

.instagram-stories-avatar.show {
  background: rgb(131, 58, 180);
  background: linear-gradient(219deg,
      rgba(131, 58, 180, 1) 0%,
      rgba(253, 29, 29, 1) 50%,
      rgba(252, 176, 69, 1) 100%);
  padding: 0.12rem;
}

.back-for-stories-effect {
  /* background: white; */
  padding: 2px;
  border-radius: 50%;
}

.header-container_specifications {
  margin-top: 35px;
  display: flex;
  overflow-x: auto;
}

.specifications_chip {
  width: auto;
  background-color: rgba(0, 0, 0, 0.1) !important;
  font-size: 0.93em !important;
  margin-right: 6px !important;
  margin-bottom: 6px !important;
  padding: 3px 1px !important;
}

.specifications_button {
  width: 100%;
  padding: 10px 16px;
  text-transform: none !important;
  box-shadow: 0 0 black !important;
  background-color: rgba(0, 0, 0, 0.12) !important;
  border-radius: 10px !important;
  font-size: 1em !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  font-weight: bold !important;
}

.language-picker {
  position: absolute;
  top: 30px;
  right: 30px;
}

.language-picker button {
  margin-right: 5px;
  padding: 5px 10px;
}
