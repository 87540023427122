.product-item {
  width: 91vw;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 7px 0px;
  padding: 0;
  padding: 15px 0;
  border-radius: 10px;
  list-style-type: none;
  box-sizing: border-box;
  transition: all 0.2s ease-in-out 0s;
}
.product-item.pdf-item {
  height: 144px;
  border: 1px solid;
  background-size: cover;
  background-position: center;
  position: relative;
}
.product-item.pdf-item svg {
  position: absolute;
  bottom: 7px;
  right: 3px;
}
.product-item.none {
  display: none;
}

.product-item-info_block {
  width: 55%;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
.product-item_name {
  margin-top: 8px;
  font-size: 18px;
  font-weight: 550;
  color: var(--black);
  cursor: pointer;
}
.product-buttons-block {
  display: flex;
  align-items: center;
}
.regulation-sum-button {
  width: 7.5em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-right: 20px; */
}
.reg-button {
  margin: 0;
}
.product-item_image {
  min-width: 146px;
  height: 146px;
  margin-right: 20px;
  border-radius: 30px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.03);
}
.cooking-time-block {
  width: 85px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  color: rgba(0, 0, 0, 0.4);
}