.restaurant-info-container {
  width: 100vw;
  height: 100vh;
  display: block;
  position: fixed;
  padding: 12px 14px;
  padding-bottom: 120px;
  top: 0;
  left: 0;
  background-color: var(--white);
  transition: all 0.3s ease-out 0s;
  transform: translateX(100%);
  overflow: auto;
  z-index: 100;
}
.restaurant-info-container.show {
  transform: translateX(0%);
}
.restaurant-info_close-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.main-block-info {
  margin-top: 28px;
  font-weight: bold;
  margin-bottom: 28px;
}
.restaurant-info-block {
  /* border: 1px solid; */
  margin: 5px 0px;
  margin-bottom: 27px;
}
.category-block-name {
  padding-bottom: 6px;
  margin-bottom: 13px;
  border-bottom: 0.1rem solid rgba(0, 0, 0, 0.1);
}
.sub-category-block {
  margin-bottom: 15px;
}
.subcategory-name {
  margin-bottom: 4px;
  font-weight: 600;
}
.subcategory-text {
  margin-bottom: 10px;
}
.restaurant-cell {
  margin: 8px 0px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.restaurant-connection {
  text-decoration: underline;
}
.restaurant-cell > svg {
  margin-right: 6px;
}
.call-to-restaurant-button {
  width: 100%;
  margin-top: 20px;
  margin-right: 20px;
  padding: 9px 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 8px;
  background: var(--main-light-color);
  color: rgba(255, 255, 255, 0.9);
  outline: 0;
}