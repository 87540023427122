.floating-button {
  width: 55px;
  height: 55px;
  position: fixed;
  bottom: 20px;
  right: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #6ea9fa;
  box-shadow: 0 0 0 0 rgba(80, 154, 255, 1);
  animation: call-turn-animo 2.5s ease-in-out 0s infinite;
  cursor: pointer;
}