.nav-categories-container {
  width: 100vw;
  padding: 10px 0;
  margin: 0 auto;
  margin-top: 0px;
  background-color: var(--white);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  transition: all 0.2s ease-in-out 0s;
}
.sticky-navbar {
  position: -webkit-sticky; /* Для Safari */
  position: sticky;
  top: 0;
  z-index: 100; /* Убедитесь, что навигационная панель находится поверх других элементов */
}
.nav-categories-container.fixed {
  width: 100vw;
  border-radius: 0px;
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 0 3px 13px -7px rgb(0, 0, 0, 0.5);
  /* backdrop-filter: blur(20px); */
  will-change: transform;
  z-index: 2;
}
.categories-block {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  position: sticky;
}

.navbar-ul {
  display: flex;
}

.navbar_item {
  width: min-content;
  padding: 7px 16px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  font-size: 1em;
  font-weight: 600;
  border: 1px solid var(--border-color);
  border-radius: 100px;
  white-space: nowrap;
  cursor: pointer;
}
.nav-categories-container.fixed .navbar_item {
  background-color: var(--white);
}
.navbar_item a {
  color: var(--black);
  font-weight: 400;
}

.navbar_item.active {
  background-color: var(--active-light-color);
}
.nav-categories-container.fixed .navbar_item.active {
  background-color: var(--active-light-color);
}
