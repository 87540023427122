.menu-container {
  margin-top: 15px;
  margin-bottom: 100px;
}
.category-item {
  width: 100vw;
  padding: 15px 0px;
  margin: 0 auto;
  margin-bottom: 25px;
  background: var(--white);
}
.category-name {
  padding: 0 26px;
  color: var(--black);
}

.category-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

@media (min-width: 768px) {
  .category-list {
    margin: 0px 10px;
    align-items: flex-start;
  }
}
