.product-item_button {
  min-height: 43px;
  min-width: 96px;
  margin-right: 20px;
  font-size: 1.1rem;
  padding: 9px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 100px;
  background: var(--white);
  box-shadow: 0 0 16px -10px var(--black);
  color: var(--black);
  outline: 0;
}
.product-item_button.popup {
  margin-right: 0;
  margin-left: 20px;
}
.product-item_price {
  color: var(--black);
  font-weight: 600;
  font-size: 18px;
}
.price-currency {
  font-size: 16px;
}
.button-progress {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12;
  margin-left: -20;
}

.product-item_button.selected {
  background: rgba(80, 154, 255, 0.65);
  box-shadow: 0 0 10px -2px rgba(80, 154, 255, 0.65);
  color: var(--white);
}
.product-item_button.selected .product-item_price {
  color: var(--white);
}

.success-checkmark {
  height: 1.3em;
}

.success-checkmark > svg {
  transform: scale(1.2);
  animation: checked-animation 0.8s;
}

@keyframes checked-animation {
  0% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1.2);
  }
}

@media (min-width: 768px) {
  .product-item_button {
    cursor: pointer;
  }
}
