.cart-block {
  width: 80vw;
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 0;
  transition: all 0.2s ease-in-out 0s;
  transform: translateY(100%);
  padding-top: 13px;
  padding-bottom: 20px;
  opacity: 0;
}

.cart-block.show {
  transform: translateY(0%);
  opacity: 1;
}

.cart-button {
  width: 93%;
  height: 55px;
  padding: 0px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 100px;
  background-color: var(--main-color);
  cursor: pointer;
}

.cart-button p {
  font-size: 18px;
  font-weight: 550;
  color: var(--white);
}

.cart-button p.cart-button_time {
  font-weight: 400;
  color: #d3ddeb;
}

.full-cart-section {
  width: 100vw;
  height: 100vh;
  display: block;
  position: fixed;
  padding: 20px 14px;
  padding-bottom: 150px;
  top: 0;
  left: 0;
  background-color: var(--white);
  transition: all 0.5s ease-in-out 0s;
  transform: translateY(120%);
  overflow: auto;
  opacity: 0.6;
  z-index: 100;
}

.full-cart-section.no-scroll {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.full-cart-section.show {
  transform: translateY(0%);
  opacity: 1;
  z-index: 100;
}

.full-cart_head-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.full-cart_button {
  font-size: 1.2em;
  font-weight: 500;
}

.close-full-cart_button {
  font-size: 1.8em;
  font-weight: 500;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(-90deg);
  /* color: #007AFF; */
  color: var(--black);
}

.clear-all-cart_button {
  /* color: var(--main-color); */
  cursor: pointer;
  padding: 10px 12px;
  color: rgba(0, 0, 0, 0.8);
  background-color: rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  font-size: 1.1em;
}

.full-cart_main-block {
  margin-top: 40px;
}

.cart-info {
  font-size: 24px;
  font-weight: bold;
  padding: 0 10px;
  color: var(--black);
}

.cart-history-block {
  display: flex;
  align-items: center;
}

.cart-history-title {
  margin-left: 8px;
  color: var(--main-color);
}

.cart-footer {
  width: 98%;
  margin: 0 auto;
  margin-top: 20px;
}

.cart-info-button>span {
  font-weight: bold;
}

.message-to-staff-button {
  width: 100%;
  padding: 15px 0px;
  margin: 30px 0px;
  border-radius: 100px;
  color: rgba(255, 255, 255, 1);
  background-color: var(--main-color);
  border: none;
  font-weight: bold;
  font-size: 19px;
  outline: 0;
  opacity: 0.4;
  transition: all 0.5s ease-in-out 0s;
}

.message-to-staff-button.show {
  opacity: 1;
}

.table-info-text {
  font-size: 1.2em;
  font-weight: 600;
  /* font-weight: 500; */
}

.table-info-text .table-num {
  font-weight: 600;
}

.set-table-block {
  display: flex;
  margin-top: 10px;
}

.set-table-input {
  /* padding: 10px; */
  width: auto;
  border: none;
  font-size: 1.1rem;
  /* font-weight: bold; */
  color: rgba(0, 0, 0, 0.5);
  outline: 0;
  appearance: none;
  -webkit-appearance: none;
}

.set-table-input::-webkit-outer-spin-button,
.set-table-input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */
}

.set-table-button {
  padding: 0px 15px;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: bold;
  background: var(--main-color);
  color: rgba(255, 255, 255, 1);
  text-transform: uppercase;
  animation: show-set-table-button 0.5s ease-in-out;
  opacity: 1;
}

.cart-message {
  padding: 0 10px;
  padding-top: 15px;
  font-size: 17px;
  font-weight: 500;
}

@keyframes show-set-table-button {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@media (min-width: 768px) {
  .cart-block {
    width: auto;
    right: 95px;
  }

  .cart-button {
    width: auto;
    min-width: 250px;
  }
}