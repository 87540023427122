* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

:root {
  --white: #fff;
  --black: #303030;
  --light-grey: #a9a9a9;
  --grey: #797979;
  --active-light-color: rgba(199, 214, 234, 0.55);
  --main-color: rgba(82, 154, 255, 1);
  --main-light-color: rgba(80, 154, 255, 0.7);
  --app-background-color: rgba(0, 0, 0, 0.035);
  --border-color: #c7d6ea;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  -ms-overflow-style: none;
}

body.no-scroll {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

textarea {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.element::-webkit-scrollbar {
  width: 0;
}

a {
  text-decoration: none;
  color: var(--black);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}