.product-item.cart {
  width: 86vw;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0px;
  padding-bottom: 25px;
}
.cart .product-item_image {
  min-width: 75px;
  height: 75px;
  margin-right: 16px;
  border-radius: 0px;
}
.cart .product-item_name {
  font-size: 16px;
  font-weight: 400;
}
.cart .product-item-info_block {
  /* width: auto; */
  justify-content: space-between;
}
.cart .product-item-info_block.second {
  justify-content: flex-start;
  /* align-items: flex-end; */
  margin-right: 0;
}
.cart .cooking-time-block {
  justify-content: flex-end;
  font-size: 16px;
  font-weight: 400;
}
.cart .product-info-block {
  display: flex;
  flex-direction: column-reverse;
}
.cart-regulation-sum-button {
  width: 7.2em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cart-product-item_button {
  width: 32px;
  height: 32px;
  font-size: 1.4em;
  padding: 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.09);
  background: #6ea9fa;
  outline: 0;
  cursor: pointer;
}
.cart-product-item_button.hidden {
  opacity: 0.2;
}
.cart-product_sum-items {
  color: var(--black);
  font-size: 1.1em;
  font-weight: 600;
}
.cart .product-item_price {
  color: var(--black);
  font-size: 16px;
  font-weight: 550;
  text-wrap: nowrap;
}
.cart .product-buttons-block {
  margin: 0;
}
.cart .cooking-time-block {
  width: auto;
  margin-top: 5px;
}

@media (min-width: 768px) {
  .product-item.cart {
    width: 50vw;
  }
}
