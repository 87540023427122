.search-container {
  padding: 3px 5px 3px 10px;
  margin-left: 15px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  transition: all 0.4s ease-in-out 0s;
  cursor: pointer;
}
.search-container.show {
  width: 100%;
  background-color: var(--white);
  border: 1px solid var(--border-color);
}
.search-status-icon-block {
  width: 20px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.search-input {
  width: 0px;
  height: 80%;
  color: var(--black);
  font-size: 16px;
  box-shadow: none;
  border: none;
  outline: 0;
  transition: all 0.4s ease-in-out 0s;
}
.search-container.show .search-input {
  width: 127px;
  animation: show-search-input 0.4s;
}
@keyframes show-search-input {
  0% {
    width: 0px;
  }
  100% {
    width: 127px;
  }
}

@media (min-width: 768px) {
  .search-container.show {
    width: 25%;
    justify-content: flex-start;
  }
}