.popup-section {
  background: rgba(0, 0, 0, 0.4);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  color: var(--black);
  z-index: 100;
  backdrop-filter: blur(3px);
}
.popup-product_block {
  width: 100%;
  height: 100vh;
  margin-top: 45%;
  padding-top: 0px;
  display: none;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: var(--white);
  transition: transform 0.4s ease-in-out 0s;
  opacity: 0;
  z-index: 5;
}
.popup-product_block.show {
  opacity: 1;
  animation: popupFromBottom 0.35s;
  display: block;
}
.popup-product_block.top-position {
  transition: transform 1s ease-in-out 0s !important;
}

@keyframes popupFromBottom {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0%);
  }
}

.popup-product-info {
  height: 70vh;
  overflow-x: hidden;
  overflow-y: scroll;
  padding-bottom: 50px;

  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.popup-block-head {
  width: 100vw;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  margin-top: -30px;
}
.popup-block-head > .popup-head-line {
  width: 4em;
  height: 5px;
  background: rgb(165, 165, 165);
  background-color: var(--white);
  border-radius: 15px;
}

.popup-product_image {
  width: 100vw;
  height: 77vw;
  position: relative;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.popup-product_content {
  width: 100vw;
  margin: 25px 0;
  padding: 0 24px;
}
.popup-product_content_header {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.popup-product_content_name {
  font-size: 24px;
  color: var(--black);
}

/* .popup-product-options {} */

.popup-product_content_description {
  margin-top: 20px;
  margin-bottom: 20px;
  color: var(--grey);
}

.popup-product-options table {
  width: 96%;
  margin: 0 auto;
  padding: 8px 0px;
  border-spacing: 7px;
  border-top: 0.1em solid rgba(0, 0, 0, 0.1);
  border-bottom: 0.1em solid rgba(0, 0, 0, 0.1);
}
table td,
th {
  text-align: center;
}
table th {
  font-size: 16px;
  font-weight: 400;
}
table td {
  font-size: 14px;
  color: #acacac;
}

.popup-product-options table tr {
  text-align: left;
}

.popup-product_footer_section {
  width: 100vw;
  padding: 0 2.5vw;
  padding-top: 1vh;
}
.footer-section_counter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0px;
}
.add-to-cart_button {
  height: 39px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--main-light-color);
  color: var(--white);
  font-size: 0.94em;
  font-weight: bold;
  padding: 0px 10px;
  border-radius: 4px;
  outline: 0;
}

@media (min-width: 768px) {
  .popup-section {
    cursor: pointer;
  }
  .popup-product_image {
    width: 43vw;
    height: 36vw;
    margin: 0 auto;
  }
  .popup-product-info {
    height: 100vh;
    cursor: default;
  }
  .popup-product_content_header {
    width: 400px;
  }
}
