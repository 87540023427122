.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.gegga {
  width: 0;
}

.snurra {
  filter: url(#gegga);
}

.stopp1 {
  stop-color: var(--main-light-color);
}

.stopp2 {
  stop-color: var(--main-color);
}

.halvan {
  animation: Snurra1 10s infinite linear;
  stroke-dasharray: 16 1000;
  fill: none;
  stroke: url(#gradient);
  stroke-width: 23;
  stroke-linecap: round;
}

.strecken {
  animation: Snurra1 3s infinite linear;
  stroke-dasharray: 50 24;
  fill: none;
  stroke: url(#gradient);
  stroke-width: 23;
  stroke-linecap: round;
}

.skugga {
  filter: blur(5px);
  opacity: 0.3;
  position: absolute;
  transform: translate(3px, 3px);
}

@keyframes Snurra1 {
  0% {
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dashoffset: -403px;
  }
}